import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let page = 2;
let limit = 8;
let offset = page === 1 ? 0 : page * limit - 8;

let items = ["Initial"];
const catalogrecords = $(".legalflix-infinive-holder");

var url = window.location.origin + window.location.pathname;

if (url.indexOf('?') > -1){
  if ($(".PodcastHolder").length > 0){
    var test = url + `&limit=${limit}&offset=${offset}`;
  }
  else{
    var test = url + `&limit=${limit}&offset=${offset}`;
  }
}
else{
  if ($(".PodcastHolder").length > 0){
    var test = url + `?limit=${limit}&offset=${offset}`;
  }
  else{
    var test = url + `?limit=${limit}&offset=${offset}`;
  }
}
let uri = test;
let fetching = false;

if(catalogrecords) {

function fetchItems() {
  fetching = true;
  var newurl = uri;
  var yourArray = [];

  $(".lf-legalflix-filter option:selected").each(function(){
    var value = $(this).val();
    if (!!value){
      var name = $(this).parent().attr('name');
      var addedurl = '&' + name + '=' + value;
      yourArray.push(addedurl);
    }
  });
  if($('.lf-legalflix-filter .field--search input[type="text"]').length > 0) {

    if ($('.lf-legalflix-filter .field--search input[type="text"]').val()){
      let value = $('.lf-legalflix-filter .field--search input[type="text"]').val();
      let name = 'search';
      var addedurl = '&' + name + '=' + value
    }
    else if ($('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder') && $('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder').indexOf("Zoeken")){
      let value = $('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  }
  else if($('#avdr-tv--search').length > 0) {
    if ($('#avdr-tv--search input[type="text"]').val()){
      let value = $('#avdr-tv--search input[type="text"]').val();
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
    else if ($('#avdr-tv--search input[type="text"]').attr('placeholder') ){
      let value = $('#avdr-tv--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  }
  else if($('#podcast--search').length > 0) {
    if ($('#podcast--search input[type="text"]').val()){
      let value = $('#podcast--search input[type="text"]').val();
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
    else if ($('#podcast--search input[type="text"]').attr('placeholder') ){
      let value = $('#podcast--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  }
  else if($('#livewebinars--search').length > 0) {
    if ($('#livewebinars--search input[type="text"]').val()){
      let value = $('#livewebinars--search input[type="text"]').val();
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
    else if ($('#livewebinars--search input[type="text"]').attr('placeholder')){
      let value = $('#livewebinars--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  }
  else if($('#project--search').length > 0 && $('.activesearch').length > 0 ) {

    if ($('#project--search input[type="text"]').val()){
      let value = $('#project--search input[type="text"]').val();
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
    else if ($('#project--search input[type="text"]').attr('placeholder')){
      let value = $('#project--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  }
  else {
    if ($('.lf-legalflix-filter .field--search input[type="text"]').val()){
      let value = $('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder');
      let name = $('.lf-legalflix-filter .field--search input[type="text"]').attr('name');
      var addedurl = '&' + name + '=' + value
    }
    else if ($('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder') ){
      let value = $('.lf-legalflix-filter .field--search input[type="text"]').attr('placeholder');
      let name = 'search'
      var addedurl = '&' + name + '=' + value
    }
  };

  yourArray.push(addedurl);

  var newurl = uri + yourArray.toString().replace(/,/g,'');


  const response = axios.get(newurl, {
    }).then(res => {
      incrementUri();
    if ( res.data.indexOf("empty") > -1 ) {
        $('.block_loadmore').fadeOut();
        $('.legalflix-infinive-holder').removeClass('activescroll');
    }
    else if (!$.trim(res.data)){
      $('.block_loadmore').fadeOut();
      $('.legalflix-infinive-holder').removeClass('activescroll');
    }
    else{

      $('.legalflix-infinive-holder .holder__container')
      .append(res.data);
    }

      fetching = false;
    })
    .catch(err => console.error(err));
}


function incrementUri() {
    page++;
    let offset = page === 1 ? 0 : page * limit - 8;

    var url = window.location.origin + window.location.pathname;
    if (url.indexOf('?') > -1){
      if ($(".PodcastHolder").length > 0){
        var test = url + `&limit=${limit}&offset=${offset}`;
      }
      else{
        var test = url + `&limit=${limit}&offset=${offset}`;
      }
    }
    else{
      if ($(".PodcastHolder").length > 0){
        var test = url + `?limit=${limit}&offset=${offset}`;
      }
      else{
        var test = url + `?limit=${limit}&offset=${offset}`;
      }
    }
    uri = test;

}



function handleScroll() {
	if(
      $('.VideosPageLegalflix').length > 0  && $('.activescroll').length > 0  ||
      $('.HomePageLegalflix').length > 0  && $('.activescroll').length > 0  ||
      $('.LiveWebinarHolderHighCouncil').length > 0  && $('.activescroll').length > 0  ||
      $('.LiveWebinarHolderJournal').length > 0  && $('.activescroll').length > 0  ||
      $('.PodcastHolder').length > 0 && $('.activescroll').length > 0  ||
      $('.ProjectPage').length > 0 && $('.activescroll').length > 0  ||
      $('.TvHolder').length > 0 && catalogrecords.length > 0 && $('.activescroll').length > 0 ||
      $('.LiveWebinarHolder').length > 0 && $('.activescroll').length > 0
    ) {
		if ($(window).scrollTop() >= catalogrecords.offset().top + catalogrecords.outerHeight() - window.innerHeight) {
			if (!fetching) {

				fetchItems();
			}
		}
	}
}

$(document).ready(() => {
  $(window).on("scroll", () => {
    if (items.length > 0 && catalogrecords.length != 0) {
      handleScroll();
    }
  });
});
}



