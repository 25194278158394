$(document).ready(function () {
  let localPopupValue = localStorage.getItem("onetime-popup-value");
  let popupValue = $('#onetime-popup-value').val();
  if (localPopupValue != popupValue) {
    window.setTimeout(function () {
      $('#onetime-popup').fadeIn(500);
    }, 1500);
    $('#onetime-popup .button').on('click', function () {
      $('#onetime-popup').fadeOut(500);
      localStorage.setItem("onetime-popup-value", popupValue);
    });
  }
});

$(document).ready(function () {
  let localPopupValue = localStorage.getItem("onetime-popup-small-value");
  if (localPopupValue != 'true') {
    window.setTimeout(function () {
      $('#onetime-popup-small').fadeIn(500);
    }, 1500);

  }

  $('body').on('click', '#onetime-popup-small', function () {
    $('#onetime-popup-small').fadeOut(500);
    localStorage.setItem("onetime-popup-small-value", 'true');
  });

});

