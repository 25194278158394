import $ from 'jquery';

$(document).ready(() => {
  function isIE() {
    // IE 10 and IE 11
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }

  const checkIE = isIE();

  if (checkIE) {
		$('#ie-banner').css('visibility', 'visible');
		$('#ie-banner').css('display', 'inline-block');
  }

  $('.js-ie-banner-close').click(() => {
    $('#ie-banner').hide()
  });
});