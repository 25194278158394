import $ from "jquery";
import axios from 'axios'

$(document).ready(() => {
  // Course print
  $(".js-print-trigger").click(() => {
    var contents = $("#print").html();

    var frame1 = document.createElement("iframe");
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);

    var frameDoc = frame1.contentWindow ?
      frame1.contentWindow :
      frame1.contentDocument.document ?
      frame1.contentDocument.document :
      frame1.contentDocument;

    const baseHref = document.getElementsByTagName("base")[0].href;
    var csslocation = `${baseHref}resources/themes/avdr/css/main.css`;

    frameDoc.document.open();
    frameDoc.document.write("<html><head>");
    frameDoc.document.write(
      '<link rel="stylesheet" href="' + csslocation + '" type="text/css" />'
    );
    frameDoc.document.write("</head><body>");
    frameDoc.document.write(contents);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();

    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);

    return false;
  });

  $("body").on('click','.js-print-certificate',function () {
    const contents = $(this)
      .parent()
      .html();

    const frame1 = document.createElement("iframe");
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow ?
      frame1.contentWindow :
      frame1.contentDocument.document ?
      frame1.contentDocument.document :
      frame1.contentDocument;

    const baseHref = document.getElementsByTagName("base")[0].href;
    const csslocation = `${baseHref}resources/themes/avdr/css/main.css`;

    console.log(csslocation);

    frameDoc.document.open();
    frameDoc.document.write("<html><head>");
    frameDoc.document.write(
      '<link rel="stylesheet" href="' + csslocation + '" type="text/css" />'
    );
    frameDoc.document.write("</head><body>");
    frameDoc.document.write(contents);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();

    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);

    return false;
  });

//   // $(".js-print-certificate-toggle").click(function () {
//   //   const poPoint = $(this).parent().siblings('.Marker').val();
//   //   let uri = `dashboard/certificaten/print/?ID=${poPoint}`;
//   //   axios.get(uri, {
//   //   }).then(res => {
//   //     $('body').append(res.data);
//   //   });


//   // });
});
