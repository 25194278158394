import $ from 'jquery';

$(document).ready(() => {
  // Filter option in dropdown box
  $('#dropdownSearch').keyup(function () {
    let input, filter, a, i, div, txtValue;
    input = $('#dropdownSearch');
    filter = input.val().toUpperCase();
    div = $('#mydropdown');
    a = $('#myDropdown a');

    for (i = 0; i < a.length; i++) {
      txtValue = a[i].text;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  });


  $('#locationSearch').keyup(function () {
    let input, filter, a, i, div, txtValue;
    input = $('#locationSearch');
    filter = input.val().toUpperCase();
    div = $('#locationDropdown');
    a = $('#locationDropdown a');

    for (i = 0; i < a.length; i++) {
      txtValue = a[i].text;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  });

  $('#subjectSearch').keyup(function () {
    let input, filter, a, i, div, txtValue;
    input = $('#subjectSearch');
    filter = input.val().toUpperCase();
    div = $('#subjectDropdown');
    a = $('#subjectDropdown a');

    for (i = 0; i < a.length; i++) {
      txtValue = a[i].text;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  });


  $('#speakerSearch').keyup(function () {
    let input, filter, a, i, div, txtValue;
    input = $('#myDropdownspeaker');
    filter = input.val().toUpperCase();
    div = $('#myDropdownspeaker');
    a = $('#myDropdownspeaker a');
    for (i = 0; i < a.length; i++) {
      txtValue = a[i].text;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  });

});
