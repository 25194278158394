$(document).ready(function($){

if ($("#map").length || $("#map1").length || $("#map2").length) {
    $(document).ready(function () {
        function initMap() {} // now it IS a function and it is in global
        initializeGoogleMap();

        $('section#map-overview').hide();
    });



        function initializeGoogleMap() {

            var locationA, locationB, marker, i, latitudeDestination, longitudeDestination, hotelLocation, eventLocation;
            var sum = [];

            var infowindow = new google.maps.InfoWindow();


            var lat = $('.marker-data').first().find('.latitude').text();
            var lon = $('.marker-data').first().find('.longitude').text();

            var count = $('.marker-data').length;

            if ($('body').hasClass('LearningPage')) {
              if (count > 1){
                var map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 5,
                    center: new google.maps.LatLng(lat, lon),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                });
              }
              else{
                var map = new google.maps.Map(document.getElementById('map'), {
                  zoom: 10,
                  center: new google.maps.LatLng(lat, lon),
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                });
              }
            }
            else if ($('body').hasClass('CategoryPage')) {
                var map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 3,
                    center: new google.maps.LatLng(41.902782, 12.496365),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                });
            }

            $('.marker-data').each(function () {
                latitude = $(this).find('.latitude').text();
                longitude = $(this).find('.longitude').text();
                link = $(this).find('.link').text();
                title = $(this).find('.title').text();
                if ($(this).hasClass('event-data')) {
                    icon = '_resources/themes/avdr/images/static/Locatiepin.svg';
                } else {
                    icon = '_resources/themes/avdr/images/static/Locatiepin.svg';
                }

                iconPath = '"' + icon + '"';
                sum.push('["' + title + '", ' + latitude + ', ' + longitude + ', ' + iconPath + ', "' + link + '"]');
            });


            var locations = JSON.parse("[" + sum + "]");

            console.log(locations);

            for (i = 0; i < locations.length; i++) {
                var icon = {
                    url: locations[i][3],
                    scaledSize: new google.maps.Size(28, 36)
                };
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                    map: map,
                    icon: icon,
                });

                console.log(locations);

                // if ($('body').hasClass('CategoryPage')) {
                  if (locations[i][4]) {
                      google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        // var contentString = '<div class="company-info"><a target="_blank" href="' + locations[i][4] + '">' +
                        // '<h5>' + locations[i][0] + '</h5>' +
                        // '</a></div>';
                        var contentString = '<div class="company-info">' +
                        '<h5>' + locations[i][0] + '</h5>' +
                        '</div>';
                          return function () {
                              infowindow.setContent(contentString);
                              infowindow.open(map, marker);
                          }
                      })(marker, i));
                  }
                // }
            }

        }
//     }
}
});
