// const keys = require('../../../keys.js')
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let page = 2;
let limit = 10;
let offset = page === 1 ? 0 : page * limit - 10;

let items = ["Initial"];
const catalogrecords = $(".ondemandfallbackrecords");

var url = window.location.href;
if (url.indexOf('?') > -1){
  var test = url + `&limit=${limit}&offset=${offset}&ondemand=yes`;
}
else{
  var test = url + `?limit=${limit}&offset=${offset}&ondemand=yes`;
}
let uri = test;
let fetching = false;

function fetchItems() {
  fetching = true;

  const response = axios.get(uri, {
    }).then(res => {
      incrementUri();
    if ( res.data.indexOf("empty") > -1 ) {
        $('.block_loadmore').fadeOut();
        $('.ondemandfallbackrecords').removeClass('activeondemandscroll');
    }
    else if (!$.trim(res.data)){
      $('.block_loadmore').fadeOut();
      $('.ondemandfallbackrecords').removeClass('activeondemandscroll');
    }
    else{

      $('.ondemandfallbackrecords')
      .append(res.data);
    }

      fetching = false;
    })
    .catch(err => console.error(err));
}


function incrementUri() {
    page++;
    offset = page === 1 ? 0 : page * limit - 10;
    var url = window.location.href;
    if (url.indexOf('?') > -1){
      var test = url + `&limit=${limit}&offset=${offset}&ondemand=yes`;
    }
    else{
      var test = url + `?limit=${limit}&offset=${offset}&ondemand=yes`;
    }
    uri = test;

}

function handleScroll() {
	if($('body').hasClass('CatalogusPage') || $('body').hasClass('SubscriptionPage') || $('body').hasClass('OnDemandSubscriptionPage')   ) {

    if ($('.ondemandfallbackrecords').length > 0 && $('.activeondemandscroll').length > 0){
      if ($(window).scrollTop() >= catalogrecords.offset().top + catalogrecords.outerHeight() - window.innerHeight) {
        if (!fetching) {
          fetchItems();
        }
      }
    }
	}

}

$(document).ready(() => {
  $(window).on("scroll", () => {
    if (items.length > 0) {
      handleScroll();
    }
  });
});
