import Axios from "axios";


if ($('body').hasClass('HomePage')) {
  console.log(window.location);
  var s = window.location.href;
  var url = s.substring(0, s.indexOf('?'));
	var url = url + 'home/getSocials';

	if(!window.location.search) {
		Axios.get(url).then((response) => {
			$('.asnycnews').html(response.data);
		}).catch(function (e) {
			console.log('error', e)
		});
	}
}

if ($('body').hasClass('ClubHolder') && !$('body').hasClass('Album')) {
  var url =  window.location.origin + window.location.pathname + '/getSocials';

	if(!window.location.search) {
		Axios.get(url).then((response) => {
			$('.asnycnews').html(response.data);
		}).catch(function (e) {
			console.log('error', e)
		});
	}
}
