$(document).ready(function () {

  $('.scrollto').on('click', function (evt) {
    evt.preventDefault();
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var hashStr = this.hash.slice(1);
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + hashStr + ']');

      if (target.length) {
        $('html,body').animate({ scrollTop: target.offset().top - 100 }, 500);
        window.location.hash = hashStr;
        return false;
      }
    }
  });

  if ($('body').hasClass('PodcastHolder')) {
    if (window.location.host == 'localhost' || window.location.host == 'localhost:8888') {
      if ($('body').find('.lf-podcast-all:not')) { } else {
        alert('test');
        if (window.location.href.split("/")[6]) {
          $('html, body').animate({
            scrollTop: $('#filter').offset().top - 100
          }, 500);
        }
      }

    } else {
      if (window.location.href.split("/")[5]) {
        if ($('body').find('.lf-podcast-all:not')) { } else {
          $('html, body').animate({
            scrollTop: $('#filter').offset().top - 100
          }, 500);
        }
      }
    }
  }


  if (window.location.href.indexOf("onderwerpen") > -1) {
    var elem = window.location.hash;
    if (elem) {
      $('html,body').animate({ scrollTop: $(elem).offset().top - 200 }, 500);
      window.location.hash = elem;
    }
  }


  $('.readmore-juristiction').on('click', function () {
    if ($(this).hasClass('activeShowmore')) {
      $('.small-juristiction').css('height', '10rem');
      $('.readmore-juristiction').removeClass('activeShowmore');
      $('.readmore-juristiction').text('Toon meer')
    }
    else {
      $('.small-juristiction').css('height', '350px');
      $('.readmore-juristiction').addClass('activeShowmore');
      $('.readmore-juristiction').text('Toon minder')
    }
  });

  $('.subjectblock li').length > 30 ? $('.showsubjects').show() : $('.showsubjects').hide();

  $('.showsubjects').on('click', function () {
    var text = $(this).text();
    if (text == 'Toon alle onderwerpen') {
      $(this).text('Toon minder onderwerpen');
      $('.subjectblock').removeClass('subjectblockHide');
    }
    else if (text == 'Toon minder onderwerpen') {
      $(this).text('Toon alle onderwerpen');
      $('.subjectblock').addClass('subjectblockHide');
    }
  });


});
