import {
  Calendar
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import nlLocale from "@fullcalendar/core/locales/nl";
import axios from 'axios'


$(document).ready(function () {
  if ($("body").hasClass("HomePage")) {

    var calendarEl = document.getElementById("calendar");
    let calendar = new Calendar(calendarEl, {
      locale: nlLocale,
      timeZone: "UTC",
      weekends: false,
      height: "auto",
      plugins: [dayGridPlugin],
      initialView: "dayGridWeek",
      events: {
        url: 'home/calendarJson',
        method: 'GET',
        failure: function () {
          console.log('Er is een probbleem met de kalender, herlaad de pagina!');
        },
      },
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false
      },
      views: {
        dayGridWeek: {
          titleFormat: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            weekday: "long"
          }
        }
      }
    });

    calendar.render();
  }
});
