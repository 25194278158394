$(document).ready(function () {

  // if ($('#catalogus_check').length  == 0){
  //   localStorage.clear();
  // }
  // if (localStorage.getItem('location')){
  //   // $('#location .title__text').text(localStorage.getItem('location'));
  //   // $( ".filter--dropdown a:contains('"+ localStorage.getItem('location') +"')" ).css( "background-color", "#0E3761" ).css( "color", "white" );
  //   var clearURL = $( ".filter--dropdown a:contains('"+ localStorage.getItem('location') +"')" ).attr('href');
  //   var clearSlug = $( ".filter--dropdown a:contains('"+ localStorage.getItem('location') +"')" ).attr('ID');
  //   $('#location .nolink').attr('href',clearURL.replace('/'+ clearSlug, ""));
  // }

  // if (localStorage.getItem('variaty')){
  //   // $('#variaty .title__text').text(localStorage.getItem('variaty'));
  //   // $( ".filter--dropdown a:contains('"+ localStorage.getItem('variaty') +"')" ).css( "background-color", "#0E3761" ).css( "color", "white" );
  //   var clearURL = $( ".filter--dropdown a:contains('"+ localStorage.getItem('variaty') +"')" ).attr('href');
  //   var clearSlug = $( ".filter--dropdown a:contains('"+ localStorage.getItem('variaty') +"')" ).attr('ID');
  //   $('#variaty .nolink').attr('href',clearURL.replace('/'+ clearSlug, ""));
  // }

  // if (localStorage.getItem('juristriction')){
  //   // $('#juristriction .title__text').text(localStorage.getItem('juristriction'));
  //   // $( ".filter--dropdown a:contains('"+ localStorage.getItem('juristriction') +"')" ).css( "background-color", "#0E3761" ).css( "color", "white" );
  //   var clearURL = $( ".filter--dropdown a:contains('"+ localStorage.getItem('juristriction') +"')" ).attr('href');
  //   var clearSlug = $( ".filter--dropdown a:contains('"+ localStorage.getItem('juristriction') +"')" ).attr('ID');
  //   $('#juristriction .nolink').attr('href',clearURL.replace('/'+ clearSlug, ""));
  // }

  // $('body').on('click', '.filter--dropdown a', function() {
  //   var text = $(this).text();
  //   var ID = $(this).closest('.filter--dropdown').attr('ID');
  //   if (text == 'Geen'){
  //     localStorage.removeItem(ID);
  //   }
  //   else{
  //     localStorage.setItem(ID, text);
  //   }
  // });


  $('#datefield').on('change', function () {
    window.location.href = "catalogus/datum?datum=" + $(this).val();
  });


  $('#Form_searchForm').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "catalogus/zoeken?search=" + val;
  });

  $('#award-form').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input[type="text"]').val();
    window.location.href = "fotowedstrijd/zoeken?search=" + val;
  });


  $('#thirthparty-form').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input[type="text"]').val();
    const url = $('.urlchecker').text();
    window.location.href = url + "zoeken?search=" + val;
  });


  if ($('body').hasClass('ClubHolder')) {
    $('#club-form').on('submit',function (evt) {
      evt.preventDefault();
      var val = $(this).find('input[type="text"]').val();
      window.location.href =  window.location.origin + window.location.pathname + "zoeken?search=" + val;
    });
  };
  $('#catalog--search .form__action').click(function () {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "catalogus/zoeken?search=" + val;
  });

  $('#catalog--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "catalogus/zoeken?search=" + val;
  });
  $('#avdr-tv--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val().replace(/\s/g, "-");
    window.location.href = "legalflix/avdr-tv/zoeken?search=" + val;
  });


  $('#podcast--search').submit(function (evt) {
    evt.preventDefault();
    var url = $('.clearform a').attr('href');
    var val = $(this).find('input').val().replace(/\s/g, "-");
    window.location.href = url + "zoeken?search=" + val;

  });

  $('#livewebinars--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val().replace(/\s/g, "-");
    window.location.href = "legalflix/live-webinars/zoeken?search=" + val;
  });

  $('#livewebinars--highcounsil--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val().replace(/\s/g, "-");
    window.location.href = "hoge-raad/zoeken?search=" + val;
  });

  $('#livewebinars--journaal--search').submit(function (evt) {
    evt.preventDefault();
    var url = $('.clearform a').attr('href');
    var val = $(this).find('input').val().replace(/\s/g, "-");
    window.location.href = url + "zoeken?search=" + val;
  });


  $('#project--search').submit(function (evt) {
    evt.preventDefault();
    var url = $('.clearform a').attr('href');
    var val = $(this).find('input').val().replace(/\s/g, "-");

    window.location.href = url + "?search=" + val;
  });

  $('#caselaw--search .form__action').click(function () {
    $('#caselaw--search').submit();
  });

  $('#caselaw--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "jurisprudentie/zoeken?search=" + val;
  });

  $('#lib--search .form__action').click(function () {
    $('#lib--search').submit();
  });

  $('#lib--search').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "bibliotheek/zoeken?search=" + val;
  });

  $('#speakersearch').submit(function (evt) {
    evt.preventDefault();
    var val = $(this).find('input').val();
    window.location.href = "legalflix/sprekers/zoeken?search=" + val;
  });

  $('#dashboardwebinar--search').on('submit', function (e) {
    e.preventDefault();
    const that = $(this);
    const action = that[0].action;

    var val = that.find('input').val();
    window.location.href = action + "?search=" + val;
  });

  $('#dashboardwebinarLib--search').on('submit', function (e) {
    e.preventDefault();
    const that = $(this);
    const action = that[0].action;

    var val = that.find('input').val();
    window.location.href = "dashboard/cursus-bibliotheek?search=" + val;
  });



  // $('body').on('change','.webinars__check input', function(event){
  //   event.stopPropagation();
  //   if ($(this).is( ":checked" ) == true) {
  //       val = $(this).val();
  //       var newURL = location.href.split("?")[0];
  //       window.location.href = newURL + val;
  //   }
  //   else{
  //       var newURL = location.href.split("?")[0];
  //       window.location.href = newURL;
  //   }
  // });

  $('body').on('change', '.ondemandinput', function (event) {
    window.location.href = 'dashboard/webinars/history/';
  });

  $('body').on('change', '.liveinput', function (event) {
    window.location.href = 'dashboard/webinars/';
  });

});
