import flatpickr from "flatpickr";
import Inputmask from "inputmask";
import SignaturePad from "signature_pad";
import axios from 'axios'
import select2 from 'select2';
import slick from 'slick-carousel';
import Bugsnag from '@bugsnag/js'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const BaseHref = $('base').attr('href');

$(document).ready(function () {

  var $windowWidth = $(window).width();
  // if ($windowWidth > 1024) {
  //   if($('body').hasClass('page-37691')) {
  //     var height = $('.hightlighted__speaker .card').height();

  //     var recencieheight = $('#recensies').height();
  //     var smallnotificationheight = $('.smallnotification').height();

  //     $('.externaldivdata').height(recencieheight + smallnotificationheight);

  //     var firstgccontent = $('.first-gc-content').height();
  //     $('.hightlighted__speaker .card').height(firstgccontent);

  //     // $('.hightlighted__speaker .card').height(height);



  //     var heightfull = $('.hightlighted__speaker').height() - 5;
  //     $('.second-gc-content').height(heightfull);

  //   };
  // }


  if($('body').hasClass('ThirdPartyPage')) {

    $('body').on('click', '.card__button', function(){

      var link = 'Security/login?BackURL=' + $(this).attr('href');
      var cleanlink = $(this).attr('href');

      if (link){
        $('.login-trigger').attr('href', link);
        $('#Form_OnlyRegisterForm_Viathirthpartyregister').val(cleanlink);
      }

    });

    $('body').on('click', '.fullclick', function(){

      var link = 'Security/login?BackURL=' + $(this).attr('href');
      var cleanlink = $(this).attr('href');

      if (link){
        $('.login-trigger').attr('href', link);
        $('#Form_OnlyRegisterForm_Viathirthpartyregister').val(cleanlink);
      }

    });

  }

  document.querySelectorAll('.dashboard__favcourses .course').forEach(item => {
    item.addEventListener('click', () => {
      item.querySelector('.course__hidden').classList.contains('is-active') ? item.querySelector('.course__hidden').classList.remove('is-active') : item.querySelector('.course__hidden').classList.add('is-active');
    });
  });

  if (window.location.search.indexOf('CustomPo') > -1) {
    window.history.pushState("object or string", "Title", window.location.href.split("?")[0]);
  } else {
  }

  $(".chosen-select").select2({ 'allowClear': true });

  $(".Form_PersonalDetailsForm_Jurisdictions").select2({
    placeholder: 'Klik hier om uw voorkeur(en) te selecteren',
  });

  $(".Form_PersonalDetailsForm_Jurisdictions").select2({
    placeholder: 'Klik hier om uw voorkeur(en) te selecteren',
  });


  $("#Form_PersonalDetailsForm_Jurisdiction").select2({
    placeholder: 'Klik hier om uw voorkeur(en) te selecteren',
  });

  $("#Form_PersonalDetailsForm_Jurisdictions").select2({
    placeholder: 'Klik hier om uw voorkeur(en) te selecteren',
  });

  $('.chosen-select').on('select2:open', function (e) {
    $('input.select2-search__field').prop('placeholder', 'zoeken..');
  });

  $('.dashboardfilter .filter__toggle').on('click', function () {
    $('.dashboardfilter').toggleClass('is-active');
  });


  $('.opqrfeed').on('click', function () {
    $('.opqrfeedpopup').toggleClass('is-active');
  });

  $('.closeqr').on('click', function () {
    $('.opqrfeedpopup').removeClass('is-active');
  });

  $('.showAllRecensies').on('click', function () {

      var text = $(this).find('.button').text();
      if (text == 'Meer recensies') {
        $('#reviews-noslides > div').show();
        $(this).find('.button').text('Minder recensies');
      }
      else if (text == 'Minder recensies') {
        $('#reviews-noslides > div').hide();
        $('#reviews-noslides > div:first-child, #reviews-noslides > div:nth-child(2)').show();
        $(this).find('.button').text('Meer recensies');
      }

  });

  $('.showAllspeakers').on('click', function () {

    var text = $(this).find('.button').text();
    if (text == 'Meer sprekers') {
      $('.speaker-noslides > div').show();
      $(this).find('.button').text('Minder sprekers');
    }
    else if (text == 'Minder sprekers') {
      $('.speaker-noslides > div').hide();
      $('.speaker-noslides > div:first-child, .speaker-noslides > div:nth-child(2)').show();
      $(this).find('.button').text('Meer sprekers');
    }
});

$('.showAllspeakers').on('click', function () {

  var text = $(this).find('.button').text();
  if (text == 'Meer leden') {
    $('.speaker-noslides > div').show();
    $(this).find('.button').text('Minder leden');
  }
  else if (text == 'Minder leden') {
    $('.speaker-noslides > div').hide();
    $('.speaker-noslides > div:first-child, .speaker-noslides > div:nth-child(2)').show();
    $(this).find('.button').text('Meer leden');
  }
});


if($('body').hasClass('CaseLawHolderPage')) {
  if (localStorage.getItem('toggledcaselaw') == 'big'){
    $('#toggle-caselaw').text('Toon kleinere weergave');
    $('body').removeClass('courses--small');
  }
  else if (localStorage.getItem('toggledcaselaw') == 'small'){
    $('#toggle-caselaw').text('Toon grotere weergave');
    $('body').addClass('courses--small');
  }


  $('body').on('click', '#toggle-caselaw',function(){
    var text = $(this).text();
    if (text == 'Toon kleinere weergave'){
      $(this).text('Toon grotere weergave');
      $('body').toggleClass('courses--small');
      localStorage.toggledcaselaw = 'small';
    }
    else if (text == 'Toon grotere weergave'){
      $(this).text('Toon kleinere weergave');
      $('body').toggleClass('courses--small');
      localStorage.toggledcaselaw = 'big';
    }
  });
};

  $('#filter a').each(function () {
    var a = new RegExp('/' + window.location.host + '/');
    if (!a.test(this.href)) {
      $(this).attr("target", "_blank");
    }
  });

  const $tlButtons = $('a.tl__action');
  if ($tlButtons.length > 0) {
    $tlButtons.on('click', async (e) => {
      å
      const $this = $(e.currentTarget);
      const cursusID = $this.data('cursus') || 0;
      const baseUrl = $this.data('baseurl') || '/';

      try {
        const {
          data
        } = await axios.post(baseUrl + 'teamleader/watch', {
          cursusID
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  if ($('#CustomMemberLoginForm_LoginForm_error').length > 0){
      if($('body').hasClass('LiveWebinarPage')) {
        var text = $('#CustomMemberLoginForm_LoginForm_error').text();
        if (text.indexOf('De ingevulde gegevens lijken niet correct. Probeer het nog een keer.') >= 0) {
          $(".quickloginpopup").addClass("is-active");
        }
      }
  }

  if (window.location.search.indexOf('open=popup') > -1) {
    $(".js-enroll-popup").addClass("is-active");
    $(".quickloginpopup").removeClass("is-active");
  }

  if (window.location.search.indexOf('open=moreinfo') > -1) {
    $(".newlearningpageprint").addClass("is-active");
  }

  if ($('.board__items').length == 0) {
    $('.nonewadds').show();
  }

  // $('body').on('click', '.ecliclearer', function (e) {
  //   var data = $('#ecliformatter').val();
  //   e.preventDefault();

  //   $('#Form_detailsForm_Jps').val('');
  // });


  $('body').on('click', '.watchmore', function (e) {
    $('.watchmore').parent().find('.item').show();
    $(this).hide();
  });

  $('body').on('click', '.OrderIDCheck input', function (e) {
    $("div.OrderID").toggle();
  });


  $('body').on('click', '.caselawondemand .showmore', function (e) {
    var text = $(this).text();
    if (text == 'Toon meer annotaties') {
      $(this).text('Toon minder annotaties');
    }
    else if (text == 'Toon minder annotaties') {
      $(this).text('Toon meer annotaties');
    }
    else if (text == 'Toon minder onderwerpen') {
      $(this).text('Toon meer onderwerpen');
    }
    else if (text == 'Toon meer onderwerpen') {
      $(this).text('Toon minder onderwerpen');
    }
    else if (text == 'Toon meer overzicht uitspraken') {
      $(this).text('Toon minder overzicht uitspraken');
    }
    else if (text == 'Toon minder overzicht uitspraken') {
      $(this).text('Toon meer overzicht uitspraken');
    }

    $(this).parent().find('.hideitems').toggle();

  });


  // $('body').on('click', '.ecliformatteradder', function (e) {
  //   var data = $('#ecliformatter').val();
  //   e.preventDefault();

  //   $('#Form_detailsForm_Jps').val($('#Form_detailsForm_Jps').val() + '\n' + data);
  //   $('#ecliformatter').val('');
  // });

  // if ($('#ecliformatter').length > 0) {
  //   var selector = document.getElementById("ecliformatter");

  //   var im = new Inputmask("aaaa:aa:aaaa:9999:9999");
  //   im.mask(selector);
  // }

  $('body').on('click', '.VideoinBcc', function () {

    $(this).find('.removeforplay').hide();
    // $(this).hide();

    var playerID = $(this).find('video').attr('ID');
    var vid = document.getElementById(playerID);

    vid.play();


  });


  if ($('#LostPasswordForm_lostPasswordForm_Email_Holder').length > 0) {
    const email = localStorage.getItem('email');

    if (email) {
      $('#LostPasswordForm_lostPasswordForm_Email').val(email);
    }
  }

  $('body').on('click', '.loginclick', function () {
    $('.toggle--login').trigger('click');
  });

  // Toggle submenu's
  $(".toggle").on("click", function () {

    $('#register').hide();
    $('.login__check').show();

    $(".is-active").removeClass(".is-active");
    $('.navigation').toggleClass('is-active');
    if ($(this).hasClass("toggle--menu")) {
      $(".toggle__icon").toggleClass("is-active");
    }

    if ($(window).width() < 1224) {
      $("html, body").toggleClass("no-scroll");
    }

    let $ID = $(this).attr("id");
    if (
      $("body")
        .find(".subnavigation")
        .hasClass($ID)
    ) {
      $(".subnavigation." + $ID).toggleClass("is-active");
    }
  });

  // Toggle dropdown fields filter
  $("html").on("click", ".field__title", function () {


    $(this).addClass('is-current');
    $(this).next('.field__wrapper').addClass('is-current');

    $(".is-active").each(function () {
      if ($(this).hasClass('is-current')) {
        return
      } else if ($(this).hasClass('field__title') || $(this).hasClass('field__wrapper')) {
        $(this).removeClass('is-active')
      }
    })

    $(this).removeClass('is-current');
    $(this).next('.field__wrapper').removeClass('is-current');

    $(this).toggleClass("is-active");
    $(this)
      .parent()
      .find(".field__wrapper")
      .toggleClass("is-active");
  });

  // Toggle filter mobile
  $("#toggle__filter").on("click", function () {
    if ($(window).width() < 1224) {
      $("html, body").toggleClass("no-scroll");
    }
    $(this).toggleClass("is-active");
    $("#filter").toggleClass("is-active");
    $("#filter")
      .find(".filter__wrapper")
      .toggleClass("is-active");
  });

  //hide filter on resizing of the screen
  $(window).on('resize', function () {
    if ($(window).width() > 1224) {
      $(".filter").removeClass('is-active');
      $('.filter__wrapper').removeClass('is-active');
    }
  });

  $(".showallcompanies").on("click", function () {
    $('.companypopup').toggleClass("is-active");
  });

  $(".companypopupclose").on("click", function () {
    $('.companypopup').toggleClass("is-active");
  });



  $(".program__toggle").on("click", function () {
    $(this).toggleClass("is-active");
    $(this)
      .parent()
      .find(".program__content")
      .toggleClass("is-active");
  });

  if ($('#datefield').length > 0) {
    var date = $('#datefield').val();
    if(date.length==0){
      date = new Date();
    }

    flatpickr(".field--date input", {
      defaultDate: date
    });
  };

  $("body").on("click", "#togglePrint", function () {
    $(".print").toggleClass("is-active");
    $("html, body").toggleClass("no-scroll");
  });

  $("body").on("click", "#togglePrintscreen", function () {
    window.print();
  });

  $("body").on("click", ".close-freepopup", function () {
    $(".freepopup").removeClass("is-active");
  });

  $("body").on("click", "#closePrint", function () {
    $(".print").removeClass("is-active");
    $('.print--certificate').remove();
    $("html, body").toggleClass("no-scroll");
  });


  $("body").on("click", ".js-closequicknewloginjpbundle", function () {
    $(".quickloginpopupnormal").removeClass("is-active");
  });


  $("body").on('click', '.registerholder', function (event) {
    event.preventDefault();
    $('.toggle--login').trigger('click');
  });



  $("body").on('click', '.register-trigger', function () {
    $('.login__check').hide();
    $('#register').show();
  });

  $("body").on('click', '.login-trigger', function () {
    $('.login__check').hide();
    $('#login').show();
  });

  $(".certificates__header").on('click', function () {
    if (
      $(this)
        .next(".certifcates__list")
        .is(":hidden")
    ) {
      $(this)
        .find(".certificates__indicator")
        .text("-");
    } else {
      $(this)
        .find(".certificates__indicator")
        .text("+");
    }

    $(this)
      .next(".certifcates__list")
      .slideToggle();
  });

  $(".progression__header").on('click', function () {
    $(this).next(".progression__list").slideToggle();
  });

  $("body").on('click', '.courses__trigger', function () {
    $('.courses__item').addClass('item--active');
    if($('.courses__item').hasClass('.item--active')) {
      setTimeout(function() {
        $('.courses__item').removeClass(".item--active");
    }, 800);
    }
    const $that = $(this);
    $that.next(".courses__hidden-content").slideToggle();
    $that.find(".courses__links.ondemand").toggleClass('hidden');
    $that.find(".courses__banner.ondemand").toggleClass('hidden');
  });


  $("body").on('click', '.certificates__trigger', function () {
    $(this).next(".certificates__hidden").slideToggle();
  });




  $("body").on('click', '.js-show-enroll-form', function () {
    $(".js-enroll-popup").addClass("is-active");
    $('.quickloginpopup').removeClass("is-active");
  });

  $("body").on('click', '.signupNotActive ul li', function () {
    // $(".js-enroll-popup").addClass("is-active");
    $('.quickloginpopupnormal').addClass("is-active");
  });


  // $("body").on('click', '.js-show-quick-login-form', function () {
  //   $(".quickloginpopup").addClass("is-active");
  // });

  $(document).on("click tap touchstart", ".js-show-quick-login-form", function(){
    $(".quickloginpopup").addClass("is-active");
  });


  $("body").on('click', '.js-login', function () {
    $("#sub--login").trigger('click');
  });

  $("body").on('click', '.js-open-newsletter', function () {
    $(".popup--newsletter").addClass("is-active");
  });
  $("body").on('click', '.js-close-newsletter', function () {
    $(".popup--newsletter").removeClass("is-active");
  });



  $("body").on('click', '.js-close-gcpopup', function () {
    $(".gcpopup").hide();

    sessionStorage.setItem("gcpopupcloseButtonClicked", "jatoch");
    sessionStorage.setItem("dataKey", "dataValue");


  });



  $("body").on('click', '.js-show-enroll-vote', function () {
    $(this).parent().parent().parent().find(".js-enroll-popup").addClass("is-active");

  });

  $("body").on('click', '.js-close-legaflixsmall', function () {
    if ($('.overviewplayer--iframe').length > 0) {
      $('.overviewplayer--iframe').attr('src', '');
      $('.ajax-loader').show();
    }
    $('.subnavigation').removeClass('is-active');
    $(".js-enroll-popup").removeClass("is-active");
    $('.webinar-stats').html('');
  });

  $("body").on('click', '.js-close-enroll-form', function () {
    if ($('.overviewplayer--iframe').length > 0) {
      $('.overviewplayer--iframe').attr('src', '');
      $('.ajax-loader').show();
    }
    $('.subnavigation').removeClass('is-active');
    $(".js-enroll-popup").removeClass("is-active");
    $(".quickloginpopupnormal").removeClass("is-active");
    $('.webinar-stats').html('');
  });


  $("body").on('click', '.showpromo', function () {
    $(this).parent().siblings('#result').toggleClass('is-active');

    // if ($('.overviewplayer--iframe').length > 0) {
    //   $('.overviewplayer--iframe').attr('src', '');
    //   $('.ajax-loader').show();
    // }
    // $('.subnavigation').removeClass('is-active');
    // $(".js-enroll-popup").removeClass("is-active");
    // $('.webinar-stats').html('');
  });

  $('body').on('click', '.triggetmailto', function () {
    var ID = $(this).attr('ID');
    $('.' + ID).addClass('sharepopupOpen');
  });

  $('body').on('click', '.sharepopup .js-close-enroll-form', function () {
    $(this).parent().parent().removeClass('sharepopupOpen');
  });

  $('body').on('click', '.js-chrome', function () {
    $(this).parent().parent().hide();
  });

  $("body").on('click', '.js-open-webinar', function () {
    $(this)
      .parent()
      .siblings('.popup--webinar')
      .addClass("is-active");
  });

  $("body").on('click', '.courses__day .js-open-webinar', function () {
    $(this)
      .parent()
      .parent()
      .parent()
      .find('.popup--webinar')
      .addClass("is-active");
  });

  $("body").on('click', '.countdown .js-open-webinar', function () {
    $(this)
      .siblings('.popup--webinar')
      .addClass("is-active");
  });

  $("body").on('click', '.js-close-webinar-popup', function () {
    $(".popup--webinar").removeClass("is-active");
  });

  $(".js-open-viewing").on('click', function () {
    $(this)
      .parentsUntil("courses__hidden-content")
      .find(".popup--viewing")
      .addClass("is-active");
  });

  $(".js-close-viewing-popup").on('click', function () {
    $('.webinar-stats').html();
    $(this)
      .parentsUntil("courses__hidden-content")
      .find(".popup--viewing")
      .removeClass("is-active");
  });



  $('body').on('click', '.favorite__icon', function (event) {
    event.stopPropagation();
    $(this).removeClass('is-clicked');
    $(this).toggleClass('is-favo');

    if ($(this).hasClass('is-favo')) {
      $(this).addClass('is-clicked');
    }

    var signup = $(this).parent().parent().find('.get-signup').val();
    var classname = $(this).parent().parent().find('.get-class').val();

    const response = axios({
      method: 'post',
      url: 'dashboard/favorieten/addToFavorites',
      data: {
        Signup: signup,
        Classname: classname
      }
    }).then(function (response) {
      $("input[value='" + signup + "']").parent().parent().parent().find('.favholder').html(response.data);
    });
  });

  // $('body').on('click', '.favorite__iconTop10', function (event) {
  //   event.stopPropagation();
  //   $(this).removeClass('is-clicked');
  //   $(this).toggleClass('is-favo');

  //   if($(this).hasClass('is-favo')) {
  //     $(this).addClass('is-clicked');
  //   }

  //   var signup = $(this).parent().parent().parent().find('.get-signup').val();

  //   const response = axios({
  //     method: 'post',
  //     url: 'dashboard/favorieten/addToFavorites',
  //     data: {
  //       Signup: signup,
  //     }
  //   }).then(function (response) {
  //     $("input[value='"+signup+"']").parent().find('.favholder').html(response.data);
  //   });
  // });


  $('body').on('click', '.question__toggle', function () {
    $(this).html() == "minder info -" ? $(this).html('meer info -') : $(this).html('minder info -');
    $(this).parent().toggleClass('is-active');
  });

  if ($('#Nocaptcha-Form_OnlyRegisterForm_Captcha').length > 0){

    $('body').on('submit','#Form_OnlyRegisterForm', function(event){
      event.preventDefault();
      var value = $('#Nocaptcha-Form_OnlyRegisterForm_Captcha textarea').val();

      if (value === '') {

        alert('ReCaptcha is verplicht');
      }
      else{
        document.getElementById("Form_OnlyRegisterForm").submit();
      }

    });
  }


  $('body').on('submit','#Form_RegisterForm,#Form_registerCourseForm', function(event){
    $(this).find('input[type=submit]').prop('disabled', true).addClass('is-disabled');
  });


  if ($('#Nocaptcha-Form_RegisterForm_Captcha').length > 0){

    $('body').on('submit','#Form_RegisterForm', function(event){

      event.preventDefault();
      var value = $('#Nocaptcha-Form_RegisterForm_Captcha textarea').val();
      if (value === '') {
        alert('ReCaptcha is verplicht');
      }
      else{
        document.getElementById("Form_RegisterForm").submit();
      }

    });
  }




  $("body").on('click', '#Form_RegisterForm_DietWhishes input', function(){
    if ($(this).val() == 1) {
        $(".dietfields").show();
    } else {
        $(".dietfields").hide();
    }
});

$("body").on('click', '#Form_registerCourseForm_DietWhishes input', function(){
  if ($(this).val() == 1) {
      $(".dietfields").show();
  } else {
      $(".dietfields").hide();
  }
});



  $("body").on("change", "#Form_RegisterForm_Email, #Form_RegisterForm_EmailAgain, #Form_OnlyRegisterForm_Email,Form_OnlyRegisterForm_EmailAgain,#Form_OnlyRegisterFormLight_Email,#Form_OnlyRegisterFormLight_EmailAgain", function () {
    $('.form__row p.exist-error').remove();
    const baseHref = document.getElementsByTagName("base")[0].href;
    var email = $(this).val();
    var emailclass = $(this).attr("name");
    $.ajax({
      type: "POST",
      url: `${baseHref}home/checkEmail/`,
      data: {
        email
      },
      success: function (data) {
        if (data == 1) {
          $('input[name="' + emailclass + '"]').after(
            "<p class='exist-error'>Je hebt al een account geregistreerd op dit e-mail adres<strong></p>"
          );
          $('input[name="' + emailclass + '"]').val("");
        } else {
          $('input[name="' + emailclass + '"]').parent().find('.exist-error').remove();
        }
      }
    });
  });


  $("body").on("change", "#Form_RegisterForm_Email, #Form_RegisterForm_EmailAgain, #Form_OnlyRegisterForm_Email,Form_OnlyRegisterForm_EmailAgain,#Form_OnlyRegisterFormLight_Email,#Form_OnlyRegisterFormLight_EmailAgain", function () {
    $('.form__row p.exist-error').remove();
    const baseHref = document.getElementsByTagName("base")[0].href;
    var email = $(this).val();
    var emailclass = $(this).attr("name");
    $.ajax({
      type: "POST",
      url: `${baseHref}home/checkSpeakerEmail/`,
      data: {
        email
      },
      success: function (data) {
        if (data == 1) {

          $('.Organisatiefield').hide();


        } else {
          $('.Organisatiefield').show();
        }
      }
    });
  });

  const checkDiscount = (e) => {
    $('.good').remove();
    $('.error').remove();

    const inputObj = $(e.currentTarget).parent().find('input');

    const baseHref = document.getElementsByTagName("base")[0].href;
    const code = inputObj.val();
    const discountInputID = inputObj.attr("ID");

    let pageID = $('#Form_registerCourseForm_ID, #Form_RegisterForm_ID, #Form_registerAndUpgradeCourseForm_ID').val();
    const pageClass = $('#Form_registerCourseForm_ClassName, #Form_RegisterForm_ClassName, #Form_registerAndUpgradeCourseForm_ClassName').val();

    const abboTypeField = $('#Form_registerCourseForm_abboTypo');

    // Replace ID with abboType as ID if the field is present
    if (abboTypeField.length > 0) {
      pageID = abboTypeField.val();
    }

    $.ajax({
      type: "POST",
      url: `${baseHref}home/checkDiscount/`,
      data: {
        code,
        pageID,
        pageClass
      },
      success: function (data) {
        const submitButton = $('input#Form_RegisterForm_action_doSaveForm, input#Form_registerCourseForm_action_registerSave,input#Form_registerAndUpgradeCourseForm_action_registerSave');
        const discountInput = $("#" + discountInputID);

        const { valid, msg } = JSON.parse(data);

        submitButton.prop('disabled', !valid);

        if (valid) {
          discountInput.parent().find('.error').remove();
          discountInput.after("<p class='good'>Deze code geldig.</p>");
        } else {
          discountInput.val("");
          $("input+small").remove();
          discountInput.parent().find('.good').remove();
          discountInput.after(`<p class='error'>${msg}</p>`);
        }
      }
    });
  }

  $('.discount-field input').on('input', function () {
    const field = $('.discount-field');
    var inputValue = $(this).val();

    console.log(field.find('.discount-check').length);

    // Only append if field is not already present
    if (field.find('.discount-check').length === 0) {
      field.append('<div class="discount-check"></div>');
      $('.discount-check').on('click', checkDiscount);

      // Disable submit button if no valid discount text found
      if (field.find('.good').length === 0) {
        // $('input#Form_RegisterForm_action_doSaveForm, input#Form_registerCourseForm_action_registerSave,input#Form_registerAndUpgradeCourseForm_action_registerSave').prop('disabled', true);
      }
    }

    if (inputValue == 0){
      // $('input#Form_RegisterForm_action_doSaveForm, input#Form_registerCourseForm_action_registerSave,input#Form_registerAndUpgradeCourseForm_action_registerSave').prop('disabled', false);
    }
  });

  $('body').on('click', '.liveondemandclicker a', function () {
    $('.liveondemandclicker a').removeClass('Active-filter-link');
    $(this).addClass('Active-filter-link');
  });

  $('body').on('click', '#Nonefilter', function () {
    $('.catalogrecords').show();
    $('.ondemandfallback').show();
    $('.ondemandfallbackrecords-more').hide();
    $('.catalogrecords-more').hide();
  });

  $('body').on('click', '#Livefilter', function () {
    $('.catalogrecords').show();
    $('.catalogrecords-more').show();
    $('.ondemandfallback').hide();
    $('.ondemandfallbackrecords-more').hide();
  });


  $('body').on('click', '#Ondemandfilter', function () {
    $('.catalogrecords').hide();
    $('.catalogrecords-more').hide();
    $('.ondemandfallback').show();
    $('.ondemandfallbackrecords-more').show();
  });

  $('body').on('click', '.annotationDiv a', function (event) {
    event.preventDefault()
    var href = $(this).attr('href');
    if (href.indexOf('youtube') >= 0) {

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = href.match(regExp);
      if (match && match[2].length == 11) {
        var url = 'https://www.youtube.com/embed/' + match[2];
      }
    }
    else if (href.indexOf('youtu') >= 0) {

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = href.match(regExp);
      if (match && match[2].length == 11) {
        var url = 'https://www.youtube.com/embed/' + match[2];
      }
    } else if (href.indexOf('vimeo') >= 0) {
      var result = href.split('/');
      var Param = result[result.length - 2];

      if (href.indexOf('video') >= 0) {
        url = href;
      } else {
        url = 'https://player.vimeo.com/video/' + Param;

      }
    }
    else if (href.indexOf('avdr.nl') >= 0) {
      var url = $(this).attr('href');
    }
    else if (href.indexOf('jwp.io') >= 0) {
      var url = $(this).attr('href');
    }

    $('.overviewplayer').show().addClass('is-active');



    if (href.indexOf('avdr.nl') >= 0) {
      // $('.overviewplayer--iframe').attr('src', url)
      $('.popup__wrapper .jsbutton').remove();
      var linkhtml = '<a class="button button--red button button--has-svg jsbutton" target="_blank" href="' + url + '">Bekijk de pagina op avdr.nl</a>'
      $('.overviewplayer--iframe').hide();
      $('.overviewplayer--iframe').before(linkhtml);
    }
    else {
      $('.jsbutton').remove();
      $('.overviewplayer--iframe').show();
      $('.overviewplayer--iframe').attr('src', url)
    }
    $(".ajax-loader").hide();
  });


  $('a.lf-podcast-latest__item').on('click', function () {
    var $ID = $(this).attr('name');
    $('html, body').animate({
      scrollTop: $('[id="' + $ID + '"]').offset().top - 100
    }, 500);
  });


  $('.bottom__type').on('click', function (e) {
    e.preventDefault()
    var URL = $(this).attr('href');
    var hash = URL.split('#').pop();

    $('html, body').animate({
      scrollTop: $('[id="' + hash + '"]').offset().top
    }, 100);
  });

  $('button#past-live-webinars-btn').on('click', function () {
    $('html, body').animate({
      scrollTop: $('#past-live-webinars').offset().top - 200
    }, 500);
  })

  $('.lf-open-source__play').find('.svg-pause').hide();
  $('.lf-open-source__play').on('click', function () {
    let video = $('.lf-open-source__video video');
    if ($(this).parent().parent().find(video)) {

      if (video[0].paused) {
        video[0].play();
        $('.lf-open-source__play').find('.svg-play').hide();
        $('.lf-open-source__play').find('.svg-pause').show();
      } else {
        $('.lf-open-source__play').find('.svg-play').show();
        $('.lf-open-source__play').find('.svg-pause').hide();
        video[0].pause();
      }
    }
  });



  if ($("#bundeljurisprudentie").length > 0){
    $('.clearnlist ul > li').each(function () {
      var classname = $(this).attr('class');
      $('.' + classname + ':gt(0)').css('display','none');
    });

    $('.futurelist > li').each(function () {

      if ($(this).find('li').length === 0){
        $(this).hide();
      }

    });


  }



});

$('.po-results').hide();
$("input#po-searchfield").on('change', function (e) {
  e.preventDefault()

  var query = $(this).val();
  window.find(query);
  $('.po-result').text(query);
  $('.po-results').show();
  //return true;
});
$('#po-submit').on('click', function (event) {
  event.preventDefault();
});



$("body").on('click', '.copyzoom', function () {

  var text = $(this).siblings('.zoomcopypass').text();
  var $temp = $("<div>");
  $("body").append($temp);
  $temp.attr("contenteditable", true)
    .html($(this).siblings('.zoomcopypass').html()).select()
    .on("focus", function () { document.execCommand('selectAll', false, null); })
    .focus();
  document.execCommand("copy");
  $temp.remove();

  alert("Het zoom wachtwoord is gekopieerd: " + text);
});

var x = 0;
var changedx = $(".wrapperSignature-signin").length - $(".wrapperSignature-signin.on").length;

if (changedx != $(".wrapperSignature-signin").length) {
  var x = changedx;
}

$(".wrapperSignature-signin.on").each(function () {
  x++;
  if ($('#signature-pad-in-' + x).length > 0) {


    if ($('#signature-pad-in-' + x).hasClass('deactive')) {

    }
    else {
      var signaturePad = new SignaturePad(document.getElementById('signature-pad-in-' + x), {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)'
      });
    }


  }
});


var x = 0;
var changedx = $(".wrapperSignature-signout").length - $(".wrapperSignature-signout.on").length;

if (changedx != $(".wrapperSignature-signout").length) {
  var x = changedx;
}

$(".wrapperSignature-signout.on").each(function () {
  x++;

  if ($('#signature-pad-out-' + x).hasClass('deactive')) {

  }
  else {

    let signaturePad = new SignaturePad(document.getElementById('signature-pad-out-' + x), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)'
    });
  }
});

$('.Sigaturebuttons .buttonsave').on('click', function (e) {

  const current = new Date();
  const time = current.toLocaleTimeString();

  var timecheck = $(this).parent().parent().siblings('ul').find('.time').val();

  if (timecheck == '') {
    $(this).parent().parent().siblings('ul').find('.activechecked').trigger('click')
      .prop('checked', true);

    $(this).parent().parent().siblings('ul').find('.activechecked').val(1).hide();


    $(this).parent().parent().siblings('ul').find('.time').val(time);
  }
});


$('.clear').on('click', function () {
  var padClass = $(this).parent().parent().find('canvas').attr('ID');
  var pad = new SignaturePad(document.getElementById(padClass));
  pad.clear();
});

// $('#Form_lockForm').removeAttr('action');



$('#Form_lockForm').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var url = $(this).attr('action');
  // var password = $('#Form_lockForm_Password').val();
  $.ajax({
    type: "POST",
    url: `${url}`,
    data: {
      data: form.serialize()
    },
    success: function (data) {

      if (data == 0) {

        $('.step1 > div').hide();

        $('.message').text('Verkeerd wachtwoord, of velden geblokt');


      } else {

        $('.step1 > div, .step1').show();
        $('.message').text('Unlocked');


        $('.wrapperSignature-signout').removeClass('notyet');

      }
    }
  });


});

$('#Form_commentsForm').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var url = $(this).attr('action');
  // var password = $('#Form_lockForm_Password').val();
  $.ajax({
    type: "POST",
    url: `${url}`,
    data: {
      data: form.serialize()
    },
    success: function (data) {

      if (data == '') {

        $('.formresult').html('<p>Er is iets verkeerd gegaan.</p>')

        document.getElementById("Form_commentsForm").reset();


      } else {

        $('.formresult').html('<p>Uw reactie wordt geplaatst na goedkeuring</p>')

        document.getElementById("Form_commentsForm").reset();

      }
    }
  });


});


$('.Form_PersonalDetailsForm_Jurisdictions').change(function (e) {
  e.preventDefault();
  const baseHref = document.getElementsByTagName("base")[0].href;

  var id = $(this).parent().parent().find('.Marker').val();
  var poPointID = $(this).val();
  $.ajax({
    type: "POST",
    url: `${baseHref}dashboard/certificaten/addJurisdictionToCert`,
    data: {
      id: id,
      poPointID: poPointID
    },
    success: function (data) {

      if (data) {

        $("input[value='" + id + "']").parent().parent().parent().parent().find('.certbyjuri').html(data);

      }
    }
  });


});



$('.wrapperSignature-signin .buttonsave').click(function (e) {
  e.preventDefault();
  var canvas = '';
  var pad = '';
  if ($(this).parent().parent().parent().find('.signature-pad').length > 0) {
    var item = $(this).parent().parent().parent().find('.signature-pad').attr('ID');
    var selecter = '#' + item;
    var canvas = document.querySelector(selecter);
    var pad = canvas.toDataURL();
    $(this).parent().parent().parent().find('.catchImage input').val(pad);
  }


  var encodeIn = pad;
  var time = $(this).parent().parent().parent().find('.time').val();
  var sgnaturein = $(this).parent().parent().parent().find('.activechecked').val();
  var checkin = $(this).parent().parent().parent().find('.checkin').val();

  var url = window.location.href + 'individualSaveStart';

  var id = $(this).parent().parent().parent().find('.singup').val();

  var upperclass = $(this).parent().parent().parent().attr('ID');

  $(this).parent().parent().remove().siblings().remove();

  $.ajax({
    type: "POST",
    url: `${url}`,
    data: {
      id: id,
      encodeIn: encodeIn,
      time: time,
      sgnaturein: sgnaturein,
      checkin: checkin
    },
    success: function (data) {

      if (data) {

        $('#' + upperclass).html(data);

      }
    }
  });


});


$('.wrapperSignature-signout .buttonsave').click(function (e) {
  e.preventDefault();
  var canvas = '';
  var pad = '';
  if ($(this).parent().parent().parent().find('.signature-pad').length > 0) {
    var item = $(this).parent().parent().parent().find('.signature-pad').attr('ID');
    var selecter = '#' + item;
    var canvas = document.querySelector(selecter);
    var pad = canvas.toDataURL();
    $(this).parent().parent().parent().find('.catchImage input').val(pad);
  }


  var encodeIn = pad;
  var time = $(this).parent().parent().parent().find('.time').val();
  var sgnaturein = $(this).parent().parent().parent().find('.activechecked').val();
  var checkin = $(this).parent().parent().parent().find('.checkin').val();

  var url = window.location.href + 'individualSaveEnd';

  var id = $(this).parent().parent().parent().find('.singup').val();

  var upperclass = $(this).parent().parent().parent().attr('ID');

  $(this).parent().parent().remove().siblings().remove();

  $.ajax({
    type: "POST",
    url: `${url}`,
    data: {
      id: id,
      encodeIn: encodeIn,
      time: time,
      sgnaturein: sgnaturein,
      checkin: checkin
    },
    success: function (data) {

      if (data) {

        $('#' + upperclass).html(data);

      }
    }
  });

});



//Toggle courses on the catalogus page
const toggleLibraryStyle = document.getElementById('toggle-library');
const body = document.querySelector('body');


var exampleURL = window.location.href;
var pageName = exampleURL.split("/")[4];


if (body.classList.contains('LibraryHolder') && pageName && toggleLibraryStyle) {
  if (localStorage.toggleLibrary == '' || localStorage.toggleLibrary == undefined) {
    body.classList.toggle('courses--small');
    document.getElementById('toggle-library').innerHTML = 'Toon grotere weergave'

    $('.slider__wrapper').addClass('books-no-slider');
    $('.book').addClass('noslider-book');

  }
  if (localStorage.toggleLibrary == 'true') {
    document.getElementById('toggle-library').innerHTML = 'Toon kleinere weergave';

    $('.slider__wrapper.books--small').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      mobileFirst: true,
      dots: true,
      infinite: true,
      prevArrow:
        `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-blue.svg"></button>`,
      nextArrow:
        `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-blue.svg"></button>`,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }

      ]
    });

  }

  toggleLibraryStyle.onclick = function () {
    body.classList.toggle('courses--small');
    if (localStorage.toggleLibrary == 'true') {
      window.localStorage.toggleLibrary = '';
      document.getElementById('toggle-library').innerHTML = 'Toon grotere weergave';

      $('.slider__wrapper.books--small').slick('unslick');
      $('.slider__wrapper').addClass('books-no-slider');
      $('.book').addClass('noslider-book');


    } else {
      window.localStorage.toggleLibrary = 'true';
      document.getElementById('toggle-library').innerHTML = 'Toon kleinere weergave';
      $('.slider__wrapper.books--small').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        mobileFirst: true,
        dots: true,
        infinite: true,
        prevArrow:
          `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-blue.svg"></button>`,
        nextArrow:
          `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-blue.svg"></button>`,
        responsive: [{
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 1380,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }

        ]
      });
    }
  }

}


//Toggle courses on the catalogus page
const toggleCoursesStyle = document.getElementById('toggle-courses');

if (toggleCoursesStyle) {
  if (localStorage.toggled == 'true') {
    body.classList.toggle('courses--small');
    document.getElementById('toggle-courses').innerHTML = 'Toon grotere weergave';
  }
  if (localStorage.toggled == '') {
    document.getElementById('toggle-courses').innerHTML = 'Toon kleinere weergave';
  }

  toggleCoursesStyle.onclick = function () {
    body.classList.toggle('courses--small');
    if (localStorage.toggled != 'true') {
      window.localStorage.toggled = 'true';
      document.getElementById('toggle-courses').innerHTML = 'Toon grotere weergave';
    } else {
      window.localStorage.toggled = '';
      document.getElementById('toggle-courses').innerHTML = 'Toon kleinere weergave';
    }
  }
}

//Toggle courses on the ondemand page
const toggleOndemandStyle = document.getElementById('toggle-ondemand');

if (toggleOndemandStyle) {
  if (localStorage.toggledOndemand == 'true') {
    body.classList.toggle('courses--small');
    document.getElementById('toggle-ondemand').innerHTML = 'Toon grotere weergave';
  }
  if (localStorage.toggled == '') {
    document.getElementById('toggle-ondemand').innerHTML = 'Toon kleinere weergave';
  }

  toggleOndemandStyle.onclick = function () {
    body.classList.toggle('courses--small');
    if (localStorage.toggledOndemand != 'true') {
      window.localStorage.toggledOndemand = 'true';
      document.getElementById('toggle-ondemand').innerHTML = 'Toon grotere weergave';
    } else {
      window.localStorage.toggledOndemand = '';
      document.getElementById('toggle-ondemand').innerHTML = 'Toon kleinere weergave';
    }
  }
}

$('body').on('click', '.lf-poscast__trigger, .lf-social__trigger', function () {
  $(this).closest('.lf-block__col-2').prev().toggleClass('is-active')
  $(this).closest('.lf-block__col-2').next().toggleClass('is-active')
  $(this).parent().parent().find('.lf-podcast--hidden').toggleClass('is-active');
});


$('body').on('click', '.block__submenu .navigation__icon', function () {
  $(this).toggleClass('is-active');
  $(this).parent().parent().find('.submenu__nav').toggleClass('is-active');
});

$('body').on('click', '.subsubmenu__item .navigation__icon', function () {
  $(this).toggleClass('is-active');
  $(this).parent().parent().find('.subsubmenu__nav').toggleClass('is-active');
});


const toggleSubscriptionStyle = document.getElementById('toggle-library');

if (toggleSubscriptionStyle) {
  if (localStorage.toggled == 'true') {
    body.classList.toggle('courses--small');
    toggleSubscriptionStyle.innerHTML = 'Toon grotere weergave';
  }
  if (localStorage.toggled == '') {
    toggleSubscriptionStyle.innerHTML = 'Toon kleinere weergave';
  }

  toggleSubscriptionStyle.onclick = function () {
    body.classList.toggle('courses--small');
    if (localStorage.toggled != 'true') {
      window.localStorage.toggled = 'true';
      toggleSubscriptionStyle.innerHTML = 'Toon grotere weergave';
    } else {
      window.localStorage.toggled = '';
      toggleSubscriptionStyle.innerHTML = 'Toon kleinere weergave';
    }
  }
}













if ($('.timestatblock').length > 0){

  if (document.querySelector(".dateget")){
    (function () {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;
            let offer = document.querySelector(".dateget").value,
            countDown = new Date(offer).getTime(),
            x = setInterval(function() {
            let now = new Date().getTime(),
            distance = countDown - now;

            document.getElementById("Tourdays").innerText = Math.floor(distance / (day)),
            document.getElementById("TourHours").innerText = Math.floor((distance % (day)) / (hour)),
            document.getElementById("Tourminutes").innerText = Math.floor((distance % (hour)) / (minute)),
            document.getElementById("TourSecondes").innerText = Math.floor((distance % (minute)) / second);

            //do something later when date is reached
            if (distance < 0) {

            clearInterval(x);
            document.querySelector(".countdownitem-1 .dateget").style.display = 'none';
            reload();



            }
        }, 0)
    }());
}

  // (function () {
  //   const second = 1000,
  //         minute = second * 60,
  //         hour = minute * 60,
  //         day = hour * 24;

  //   //I'm adding this section so I don't have to keep updating this pen every year :-)
  //   //remove this if you don't need it
  //   let today = new Date(),
  //       dd = String(today.getDate()).padStart(2, "0"),
  //       mm = String(today.getMonth() + 1).padStart(2, "0"),
  //       yyyy = today.getFullYear(),
  //       nextYear = yyyy + 1,
  //       dayMonth = "09/30/",
  //       birthday = dayMonth + yyyy;

  //   today = mm + "/" + dd + "/" + yyyy;
  //   if (today > birthday) {
  //     birthday = dayMonth + nextYear;
  //   }
  //   //end

  //   const countDown = new Date(birthday).getTime(),
  //       x = setInterval(function() {

  //         const now = new Date().getTime(),
  //               distance = countDown - now;

  //           document.getElementById("Tourdays").innerText = Math.floor(distance / (day)),
  //           document.getElementById("TourHours").innerText = Math.floor((distance % (day)) / (hour)),
  //           document.getElementById("Tourminutes").innerText = Math.floor((distance % (hour)) / (minute)),
  //           document.getElementById("TourSecondes").innerText = Math.floor((distance % (minute)) / second);


  //       }, 0)
  //   }());

};

if(document.querySelector('.rules__toggle')) {
  document.querySelector('.rules__toggle').onclick = function () {
    document.querySelector('.rules__toggle').classList.toggle('is-active');
    document.querySelector('.card__rules').classList.toggle('is-active');
  };
}

if ($('.dashboard-honerarium').length > 0) {
  document.getElementById('Form_detailsForm_action_save').addEventListener('click', function() {
    this.disabled = true;

    var answer = window.confirm("Weet u zeker dat de gegevens juist zijn?");
    if (answer) {
      document.getElementById('Form_detailsForm').submit(); // Assuming you have a form with ID 'myForm'
    }

  });
}


const category = document.querySelectorAll(".category");
const faq = document.querySelectorAll(".faq");
const faqMobile = document.querySelector(".select-category-mobile");
const faqContainer = document.querySelector('.faq__container');
const faqCategories = document.querySelector('.faqs__categories');


let savedScrollPosition = 0;
window.addEventListener("scroll", () =>
{
  if (!faqContainer) {
    return;
  }

  const y = faqContainer.getBoundingClientRect().top;

  if (y > savedScrollPosition && y < 50) {
    faqCategories.classList.add('is-pushed');
  } else {
    faqCategories.classList.remove('is-pushed');
  }

  savedScrollPosition = y;
});

if (category.length > 0) {
  category.forEach((item) => {
    item.addEventListener("click", () => {
      category.forEach((item) => {
        item.classList.remove("is-active");
      });
      item.classList.add("is-active");
      window.scrollTo(0, 0);

      if (item.classList.contains("is-active")) {
        faq.forEach(function (el) {
          if (el.dataset.id === item.dataset.id) {
            el.style.display = "inline-block";
          } else {
            el.style.display = "none";
          }
        });
      } else {
        faq.forEach(function (el) {
          el.style.display = "block";
        });
      }
    });
  });
}

if (faqMobile) {
  faqMobile.querySelector('select').addEventListener("change", (e) => {
    faq.forEach(function (el) {
      if (el.dataset.id === e.target.value) {
        el.style.display = "inline-block";
      } else {
        el.style.display = "none";
      }
    });
  });
}

if($('body').hasClass('page-37691')) {




  $('body').on('click', '.domainholder--item', function(){

    var value = $(this).find('.hiddensearchfield').text();

    var imagedata = $(this).find('.img-finder').attr('src');

    console.log(imagedata)

    $('#Form_RegisterForm_CompanyName').val(value);


    $('.viaOffice').show();

    $('.Nocompany').hide();

    if ($('.NoFind').length  == 1){
      $('.gcdatafield').addClass('hide-important');

    }

    if ($('.outfillcompany').length  > 1){
      $('.outfillcompany').text(value);
      $('.imageholder img').attr('src', imagedata);

      // $('#Form_RegisterForm_EmailAgain').val('@' + value.toLowerCase()  + '.nl');
      // $('#Form_RegisterForm_Email').val('@' + value.toLowerCase()  + '.nl');

    }



  });
};
