// const keys = require('../../../keys.js')
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let page = 2;
let limit = 50;
let offset = page === 1 ? 0 : page * limit - 50;

let items = ["Initial"];
const speakers = $(".speaker__wrapper");

var url = window.location.href;
if (url.indexOf('?') > -1){
  var test = url + `&limit=${limit}&offset=${offset}`;
}
else{
  var test = url + `?limit=${limit}&offset=${offset}`;
}
let uri = test;
let fetching = false;

function fetchItems() {
  fetching = true;

  const response = axios.get(uri, {
    }).then(res => {
      incrementUri();
    if ( res.data.indexOf("empty") > -1 ) {
        $('.block_loadmore').fadeOut();
        $('.speaker__wrapper').removeClass('activescroll');

    }
    else if (!$.trim(res.data)){  
      $('.block_loadmore').fadeOut();
      $('.speaker__wrapper').removeClass('activescroll');
    }
    else{
      $('.speaker__wrapper')
      .append(res.data);
    }

      fetching = false;
    })
    .catch(err => console.error(err));
}


function incrementUri() {
    page++;
    offset = page === 1 ? 0 : page * limit - 50;
    var url = window.location.href;
    if (url.indexOf('?') > -1){
      var test = url + `&limit=${limit}&offset=${offset}`;
    }
    else{
      var test = url + `?limit=${limit}&offset=${offset}`;
    }
    uri = test;

}

function handleScroll() {
	if($('body').hasClass('SpeakerHolder') && speakers.length > 0 && $('.activescroll').length > 0) {
		if ($(window).scrollTop() >= speakers.offset().top + speakers.outerHeight() - window.innerHeight) {
			if (!fetching) {
				fetchItems();
			}
		}
	}
 
}

$(document).ready(() => {
  $(window).on("scroll", () => {
    if (items.length > 0) {
      handleScroll();
    }
  });
});