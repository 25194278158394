// const keys = require('../../../keys.js')
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let page = 2;
let limit = 16;
let offset = page === 1 ? 0 : page * limit - 16;


let items = ["Initial"];
const offsetfrompage = $("#offset").val();
const catalogrecords = $(".dashboard-infinive-holder");

var url = window.location.href;
if (url.indexOf('?') > -1){
  var test = url + `&limit=${limit}&offset=${offset}`;
}
else{
  var test = url + `?limit=${limit}&offset=${offset}`;
}
let uri = test;
let fetching = false;

function fetchItems() {
  fetching = true;
  var newurl = uri;
  // var addedurl = '';
  var yourArray = [];

  $(".dashboard__filter option:selected").each(function(){
    var value = $(this).val();
    if (!!value){
      var name = $(this).parent().attr('name');
      var addedurl = '&' + name + '=' + value;
      yourArray.push(addedurl);

    }
  });

  var newurl = uri + yourArray.toString().replace(/,/g,'');

  const response = axios.get(newurl, {
    }).then(res => {
      incrementUri();
    if ( res.data.indexOf("empty") > -1 ) {
        $('.block_loadmore').fadeOut();
        $('.dashboard-infinive-holder').removeClass('activescroll');
    }
    else if (!$.trim(res.data)){
      $('.block_loadmore').fadeOut();
      $('.dashboard-infinive-holder').removeClass('activescroll');
    }
    else{

      $('.dashboard-infinive-holder')
      .append(res.data);
    }
      //remove double months
      $('.dashboard__courses .courses__month').each(function () {
			$('.dashboard__courses .courses__month:contains("' + $(this).text() + '"):gt(0)').remove();
      });

      fetching = false;
    })
    .catch(err => console.error(err));
}


function incrementUri() {
    page++;
    offset = page === 1 ? 0 : page * limit - 16;
    var url = window.location.href;
    if (url.indexOf('?') > -1){
      var test = url + `&limit=${limit}&offset=${offset}`;
    }
    else{
      var test = url + `?limit=${limit}&offset=${offset}`;
    }
    uri = test;

}


function handleScroll() {
	if($('.dashboard').length > 0 && catalogrecords.length > 0 && $('.activescroll').length > 0) {
		if ($(window).scrollTop() >= catalogrecords.offset().top + catalogrecords.outerHeight() - window.innerHeight) {
			if (!fetching) {
				fetchItems();
			}
		}
	}

}

$(document).ready(() => {
  $(".dashboard__main").scroll(function() {
    if (items.length > 0) {
      handleScroll();
    }
  });
  $(window).scroll(function() {
    if (items.length > 0) {
      handleScroll();
    }
  });
});
