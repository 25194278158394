import $, { each } from 'jquery';


$(document).ready(() => {
	$('.lf-podcast__audio audio').audioPlayer({
		strPlay: 'Play',
    strPause: 'Pause',
    strVolume: 'Volume'
	});
});
