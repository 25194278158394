$(document).ready(function() {
  if ($("#mapmytravel").length) {
      initializeGoogleMap();
      $('section#map-overview').hide();
  }

  $('.svg-arrow-down').on('click', function() {
    // $(this).parent().siblings('.subul').toggle()
    $('.subul').hide();
    $(this).siblings('.dashboard-nav__item').trigger('click')
  });


  $('.travel-travel-page .event.info-button').on('click', function (e) {

    $('.number-circle').removeClass('active');
    $(this).find('.number-circle').addClass('active');

  });


  $('.dashboard-nav__item-sub').on('click', function (e) {
    console.log('click');
    var identifier = $(this).attr('id');
    $('.accordion-header').removeClass('accordion-clicked');
    $('.accordion-div').removeClass('accordion-div-open');
    $('.dashboard-nav__item-sub').removeClass('active-menu-bar');
    $(this).addClass('active-menu-bar');

    $('.location-list-item').addClass('location-closed');
    $('.' + identifier).parent().removeClass('location-closed');
    var targetElement = $('.' + identifier);

    // Trigger click event on the target element
    targetElement.trigger('click');

    // Scroll to the target element
    if (targetElement.length) {
      targetElement[0].scrollIntoView({ behavior: 'smooth' });
    }

  });

  $('.showmoreimages').on('click', function (e) {
    $('[data-fancybox="gallery"]').first().trigger('click');
  });

  $('.showmoreimagestwo').on('click', function (e) {
    $('[data-fancybox="gallery-videos"]').first().trigger('click');
  });


  $('.dashboard-nav__item').on('click', function (e) {

    var identifier = $(this).attr('id');
    $('.accordion-header').removeClass('accordion-clicked');
    $('.accordion-div').removeClass('accordion-div-open');
    $('.dashboard-nav__item').removeClass('active-menu-bar');
    $('.dashboard-nav__item-sub').removeClass('active-menu-bar');
    $('.subul').hide();

    $(this).addClass('active-menu-bar');

    var targetElement = $('.' + identifier);


    if ($(this).hasClass('hassub')) {
      $(this).addClass('active-menu-bar');
      $(this).parent().siblings('.subul').toggle();
      $('.dashboard-nav__item-sub').removeClass('active-menu-bar');
      $(this).parent().parent().find('.dashboard-nav__item-sub').first().addClass('active-menu-bar');

      $('.location-list-item').addClass('location-closed');
      $(targetElement).parent().removeClass('location-closed');

    };
    console.log(targetElement);
    // Trigger click event on the target element
    targetElement.trigger('click');

    // Scroll to the target element
    if (targetElement.length) {
      targetElement[0].scrollIntoView({ behavior: 'smooth' });
    }

  });


  $('.accordion-header').on('click', function (e) {

    var identifier = $(this).attr('id');
    var newStr = identifier.replace("header", "click");

    $(this).parent().siblings().find('.accordion-header').removeClass('accordion-clicked');
    $(this).parent().siblings().find('.accordion-div').removeClass('accordion-div-open');

    $('.dashboard-nav__item').removeClass('active-menu-bar');
    $('#' + newStr).addClass('active-menu-bar');

    if ($(this).siblings().hasClass('accordion-div-open')) {
      $(this).removeClass('accordion-clicked');
      $(this).siblings().removeClass('accordion-div-open');
    }
    else{
      $(this).addClass('accordion-clicked');
      $(this).siblings().addClass('accordion-div-open');
    }

    if ($('.accordion-clicked').length == 0) {
      $('.dashboard-nav__item').removeClass('active-menu-bar');
    }

    var targetElement = $('#' + identifier);
    if (targetElement.length) {
      targetElement[0].scrollIntoView({ behavior: 'smooth' });
    }

  });




});


function initializeGoogleMap() {
  let map;
  let markers = [];
  var infowindow = new google.maps.InfoWindow();

  var lat = $('.marker-data').first().find('.latitude').text();
  var lon = $('.marker-data').first().find('.longitude').text();

  const mapOptions = {
      zoom: 12,
      center: new google.maps.LatLng(lat, lon),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
  };

  map = new google.maps.Map(document.getElementById('mapmytravel'), mapOptions);

  const locations = [];
  $('.marker-data').each(function() {
      var latitude = $(this).find('.latitude').text();
      var longitude = $(this).find('.longitude').text();
      var link = $(this).find('.link').text();
      var title = $(this).find('.title').text();
      var ID = $(this).find('.IDitem').text();

      var icon = 'https://www.avdr.nl/_resources/themes/avdr/images/static/Locatiepin.svg';

      locations.push({ title: title, latitude: latitude, longitude: longitude, iconPath: icon, link: link, ID: ID });
  });


  locations.forEach(location => {
      const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.latitude, location.longitude),
          map: map,
          title: location.title,
          link: location.link,
          icon: {
              url: location.iconPath,
              scaledSize: new google.maps.Size(28, 36) // Optional: scale icon size
          }
      });

      google.maps.event.addListener(marker, 'click', (function (marker) {


        if (location.link){
          var linkblock = '<a target="_blank" style="color:white; margin-top:-20px" href="' + location.link + '">Meer informatie</a>';
        }
        else{
          var linkblock ='';
        }

        var contentString = '<div class="company-info">' +
        '<h5>' + location.title + '</h5>' + linkblock
        '</div>';
          return function () {

            $('.number-circle').removeClass('active');
            $('.Listclick-' + location.ID + ' .number-circle').addClass('active');

              infowindow.setContent(contentString);
              infowindow.open(map, marker);
          }
      })(marker));

      markers.push(marker);

      document.querySelectorAll('.info-button').forEach((button, index) => {

        if (markers[0].link){
          var linkblockOne = '<a target="_blank" style="color:white; margin-top:-20px" href="' + markers[0].link + '">Meer informatie</a>';
        }
        else{
          var linkblockOne ='';
        }

        infowindow.setContent('<div class="company-info"><h5>'+ markers[0].title +'</h5>'+ linkblockOne +'</div>');
        infowindow.open(map, markers[0]);

        button.addEventListener('click', () => {
            count = index;

            console.log(index);
            console.log(button);

            if (markers[index].link){
              var linkblock = '<a target="_blank" style="color:white; margin-top:-20px" href="' + markers[index].link + '">Meer informatie</a>';
            }
            else{
              var linkblock ='';
            }

              infowindow.setContent('<div class="company-info"><h5>'+ markers[index].title +'</h5>'+ linkblock +'</div>');

                infowindow.open(map, markers[index]);

            });
      });

  });


}
