// const keys = require('../../../keys.js')
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let page = 2;
let limit = 10;
let offset = page === 1 ? 0 : page * limit - 10;

let items = ["Initial"];
const catalogrecords = $(".catalogrecords");

var url = window.location.href.split('#')[0];
if (url.indexOf('?') > -1){
  var test = url + `&limit=${limit}&offset=${offset}`;
}
else{
  var test = url + `?limit=${limit}&offset=${offset}`;
}
let uri = test;
let fetching = false;

function fetchItems() {
  fetching = true;

  const response = axios.get(uri, {
    }).then(res => {
      incrementUri();
    if ( res.data.indexOf("empty") > -1 ) {
      if($('body').hasClass('SubscriptionPage')) {
        $('.catalogrecords').parent().find('.block_loadmore').fadeOut();
      } else {
        $('.block_loadmore').fadeOut();
      }

        $('.catalogrecords').removeClass('activescroll');
    }
    else if (!$.trim(res.data)){
      if($('body').hasClass('SubscriptionPage')) {
        $('.catalogrecords').parent().find('.block_loadmore').fadeOut();
      } else {
        $('.block_loadmore').fadeOut();
      }
      $('.catalogrecords').removeClass('activescroll');
    }
    else if (!$.trim(res.data)){
      if($('body').hasClass('OnDemandSubscriptionPage')) {
        $('.catalogrecords').parent().find('.block_loadmore').fadeOut();
      } else {
        $('.block_loadmore').fadeOut();
      }
      $('.catalogrecords').removeClass('activescroll');
    }
    else if (!$.trim(res.data)){
      if($('body').hasClass('AvdrNew')) {
        $('.catalogrecords').parent().find('.block_loadmore').fadeOut();
      } else {
        $('.block_loadmore').fadeOut();
      }
      $('.catalogrecords').removeClass('activescroll');
    }
    else{
      $('.catalogrecords')
      .append(res.data);
    }

      fetching = false;
    })
    .catch(err => console.error(err));
}


function incrementUri() {
    page++;
    offset = page === 1 ? 0 : page * limit - 10;
    var url = window.location.href.split('#')[0];
    if (url.indexOf('?') > -1){
      var test = url + `&limit=${limit}&offset=${offset}`;
    }
    else{
      var test = url + `?limit=${limit}&offset=${offset}`;
    }
    uri = test;

}

function handleScroll() {
	if($('body').hasClass('AvdrNew') || $('body').hasClass('CatalogusPage') || $('body').hasClass('LibraryHolderPage') || $('body').hasClass('SubscriptionBundlePage')  || $('body').hasClass('SubscriptionPage') || $('body').hasClass('OnDemandSubscriptionPage') || $('body').hasClass('LearningPage') ) {
    if ($('.catalogrecords').length > 0 && $('.activescroll').length > 0){
      if ($(window).scrollTop() >= catalogrecords.offset().top + catalogrecords.outerHeight() - window.innerHeight) {
        if (!fetching) {
          fetchItems();
        }
      }
    }
	}

}

$(document).ready(() => {
  $(window).on("scroll", () => {
    if (items.length > 0) {
      handleScroll();
    }
  });
});
