    function reload(){
      const baseHref = document.getElementsByTagName("base")[0].href;

      $.ajax({
        type: "POST",
        url: `${baseHref}dashboard/overzicht/reload`,
        success: function (data) {

          if (data) {

            $(".onairholder").html(data);
            if ($('.countdownitem-2 .dateget').length > 0){
            }
            if ($('.countdownitem-1 .dateget').length > 0){

            }
          }
        }
      });
    }
